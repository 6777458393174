import { usePermission } from '@hooks/usePermission';
import { ROUTES } from '@router/routes';
export const useVisitFarm = ({ userId, isProductPlanActive, farmSeason, isDndcFarm }) => {
    var _a;
    const { canVisitAssociateFarm, canVisitPartnerFarm, canVisitAssignedAssociateFarm, canVisitAssignedPartnerFarm } = usePermission();
    let pathToFarm = `${process.env.FARMER_PORTAL_URL_V2}${ROUTES.VISIT_FARM}/${userId}`;
    if (isDndcFarm) {
        pathToFarm = `${process.env.FARMER_PORTAL_URL_V3}/${
        // Avoid redirecting to a farm season without a season id
        ((_a = farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.season) === null || _a === void 0 ? void 0 : _a.id) ? farmSeason.id : farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.baseline_farm_season_id}`;
    }
    const canVisitFarm = canVisitAssociateFarm || canVisitPartnerFarm || canVisitAssignedAssociateFarm || canVisitAssignedPartnerFarm;
    const isBaseline = !(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.baseline_farm_season_id);
    const farmSeasonSelected = !!(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.season);
    const isFarmReadyForVisit = (isBaseline && isProductPlanActive && farmSeasonSelected) || !isBaseline;
    return { pathToFarm, canVisitFarm, isFarmReadyForVisit };
};

import { api } from '@services/api.service';
export const ALARMING_DATA_TYPE = {
    YOY: 'YOY',
    BENCHMARK: 'BENCHMARK',
};
export const alarmingDataApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAlarmingData: builder.query({
            query: ({ farmSeasonId }) => ({
                url: `/private/alarming-data/${farmSeasonId}`,
                method: 'GET',
            }),
            providesTags: ['AlarmingData'],
        }),
        getAlarmingDataProcess: builder.query({
            query: ({ farmSeasonId }) => ({
                url: `/private/alarming-data/process/${farmSeasonId}`,
                method: 'GET',
            }),
        }),
        confirmAlarmingData: builder.mutation({
            query: ({ alarmingDataIds }) => ({
                url: `/private/alarming-data/confirm`,
                method: 'PUT',
                body: {
                    alarming_data_ids: alarmingDataIds,
                },
            }),
            invalidatesTags: ['AlarmingData'],
        }),
        postComment: builder.mutation({
            query: ({ alarmingDataIds, comment }) => ({
                url: `/private/alarming-data/comments`,
                method: 'POST',
                body: {
                    alarmingDataIds,
                    comment,
                },
            }),
            invalidatesTags: ['AlarmingData'],
        }),
        updateComment: builder.mutation({
            query: ({ commentId, comment }) => ({
                url: `/private/alarming-data/comments/${commentId}`,
                method: 'PUT',
                body: {
                    comment,
                },
            }),
            invalidatesTags: ['AlarmingData'],
        }),
        deleteComment: builder.mutation({
            query: ({ commentId }) => ({
                url: `/private/alarming-data/comments/${commentId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AlarmingData'],
        }),
    }),
});
export const { useGetAlarmingDataQuery, useGetAlarmingDataProcessQuery, useConfirmAlarmingDataMutation, usePostCommentMutation, useUpdateCommentMutation, useDeleteCommentMutation, } = alarmingDataApi;

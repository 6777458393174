import { useAuth } from '@hooks';
import { usePermission } from '@hooks/usePermission';
import { RESULTS_CALCULATION_STATUS, useGetUserNameByIdQuery } from '@services';
import { CARBON_RESULT_MODEL, FARMER_STATUS } from '@utils/constants';
import { formatDate } from '@utils/date';
import { useTranslation } from 'react-i18next';
export const OPERATIONAL_DATA_STATUSES = {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
};
export const useOperationalDataStatus = ({ farmSeason, carbonModel, farmerStatus }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const hasValidatedData = !!(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.summary_validated_at);
    const hasValidatedResults = !!(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_validated_by_agronomist);
    const hasValidatedOperations = !!(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.operations_validated_at);
    const areOperationsInProgress = !!((farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.farm_season_operations) && farmSeason.farm_season_operations.length > 0);
    const { canFinalizeFarmSeason, canValidateAllResults, canValidateAssignedFarmersResults } = usePermission();
    const canConfirmAndComputeResults = canValidateAllResults || canValidateAssignedFarmersResults;
    const canSeeResultComputation = farmerStatus === FARMER_STATUS.AGRONOMIST_DATA_INPUT || hasValidatedResults || hasValidatedData;
    const canValidateData = canSeeResultComputation && canFinalizeFarmSeason;
    const { data: validationPerformedBy } = useGetUserNameByIdQuery(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.summary_validated_by, {
        skip: !!(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.summary_validated_by) || !(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.summary_validated_by),
    });
    const operationValidationDate = carbonModel === CARBON_RESULT_MODEL.CFT
        ? formatDate(user === null || user === void 0 ? void 0 : user.language.iso_code, farmSeason.operations_validated_at)
        : formatDate(user === null || user === void 0 ? void 0 : user.language.iso_code, farmSeason.summary_validated_at);
    const getOperationalDataStatus = () => {
        if (carbonModel === CARBON_RESULT_MODEL.CFT ? hasValidatedOperations : hasValidatedData)
            return OPERATIONAL_DATA_STATUSES.DONE;
        if (areOperationsInProgress)
            return OPERATIONAL_DATA_STATUSES.IN_PROGRESS;
        return OPERATIONAL_DATA_STATUSES.NOT_STARTED;
    };
    const status = getOperationalDataStatus();
    const mapOperationalDataStatusToLabel = (status) => {
        switch (status) {
            case OPERATIONAL_DATA_STATUSES.NOT_STARTED:
                return t('labels.not-started', { context: 'female' });
            case OPERATIONAL_DATA_STATUSES.IN_PROGRESS:
                return t('labels.in-progress');
            case OPERATIONAL_DATA_STATUSES.DONE:
                return operationValidationDate;
        }
    };
    const translatedStatus = mapOperationalDataStatusToLabel(status);
    const operationalDataStatus = {
        label: t('labels.operational-data-entry'),
        status,
        isActive: carbonModel === CARBON_RESULT_MODEL.CFT
            ? farmerStatus === FARMER_STATUS.AGRONOMIST_DATA_INPUT ||
                hasValidatedOperations ||
                areOperationsInProgress
            : farmerStatus === FARMER_STATUS.AGRONOMIST_DATA_INPUT || hasValidatedData,
        value: translatedStatus,
    };
    const isV3ResultsCalculationOngoing = (farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_calculation_status) === RESULTS_CALCULATION_STATUS.ONGOING;
    return {
        operationalDataStatus,
        canValidateData,
        validationPerformedBy,
        hasValidatedOperations,
        areOperationsInProgress,
        isV3ResultsCalculationOngoing,
        canConfirmAndComputeResults,
    };
};

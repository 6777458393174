import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGetFarmerProfileFromUrlId } from '@features/farmer/profile/hooks/useGetFarmerProfileFromUrlId';
import { usePermission } from '@hooks/usePermission';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { usePostDeferredPaymentMutation } from '@services';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDeferredPaymentBannerStyle from './DeferredPaymentBanner.style';
export const DeferredPaymentBanner = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    const { classes } = useDeferredPaymentBannerStyle();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { canApplyDeferredPayment, canApplyDeferredPaymentAssignedFarmers } = usePermission();
    const { profile } = useGetFarmerProfileFromUrlId();
    const computedCanApplyDeferredPayment = canApplyDeferredPayment || canApplyDeferredPaymentAssignedFarmers;
    const baselineFarmSeason = (_c = (_b = (_a = profile === null || profile === void 0 ? void 0 : profile.farm_user) === null || _a === void 0 ? void 0 : _a.farm) === null || _b === void 0 ? void 0 : _b.farm_seasons) === null || _c === void 0 ? void 0 : _c.filter((farmSeason) => farmSeason.baseline_farm_season_id === null)[0];
    const farmSeasonId = baselineFarmSeason === null || baselineFarmSeason === void 0 ? void 0 : baselineFarmSeason.id;
    const farmSeasonProductPlanId = (_e = (_d = baselineFarmSeason === null || baselineFarmSeason === void 0 ? void 0 : baselineFarmSeason.farm_season_product_plan) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.id;
    const hasBaselineStarted = !(baselineFarmSeason === null || baselineFarmSeason === void 0 ? void 0 : baselineFarmSeason.baseline_farm_season_id) && !!(baselineFarmSeason === null || baselineFarmSeason === void 0 ? void 0 : baselineFarmSeason.season_id);
    const hasPaid = !!((_g = (_f = baselineFarmSeason === null || baselineFarmSeason === void 0 ? void 0 : baselineFarmSeason.farm_season_product_plan) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.paid_at) ||
        ((_j = (_h = baselineFarmSeason === null || baselineFarmSeason === void 0 ? void 0 : baselineFarmSeason.farm_season_product_plan) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.status) === 'pending' ||
        ((_l = (_k = baselineFarmSeason === null || baselineFarmSeason === void 0 ? void 0 : baselineFarmSeason.farm_season_product_plan) === null || _k === void 0 ? void 0 : _k[0]) === null || _l === void 0 ? void 0 : _l.status) === 'payment_in_progress' ||
        !!((_o = (_m = baselineFarmSeason === null || baselineFarmSeason === void 0 ? void 0 : baselineFarmSeason.farm_season_product_plan) === null || _m === void 0 ? void 0 : _m[0]) === null || _o === void 0 ? void 0 : _o.stripe_invoice_id);
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(!!((_q = (_p = baselineFarmSeason === null || baselineFarmSeason === void 0 ? void 0 : baselineFarmSeason.farm_season_product_plan) === null || _p === void 0 ? void 0 : _p[0]) === null || _q === void 0 ? void 0 : _q.deferred_payment_start_date) &&
        ((_s = (_r = baselineFarmSeason === null || baselineFarmSeason === void 0 ? void 0 : baselineFarmSeason.farm_season_product_plan) === null || _r === void 0 ? void 0 : _r[0]) === null || _s === void 0 ? void 0 : _s.status) === 'active');
    const isDeferredPaymentActivable = (profile === null || profile === void 0 ? void 0 : profile.is_eligible) === true && !!baselineFarmSeason && !hasPaid && !hasBaselineStarted;
    const isDeferredPaymentActive = !!((_u = (_t = baselineFarmSeason === null || baselineFarmSeason === void 0 ? void 0 : baselineFarmSeason.farm_season_product_plan) === null || _t === void 0 ? void 0 : _t[0]) === null || _u === void 0 ? void 0 : _u.deferred_payment_start_date);
    const [postDeferredPayment, { isSuccess, isError }] = usePostDeferredPaymentMutation();
    const handleSwitch = () => {
        setIsLoading(true);
        if (farmSeasonProductPlanId && farmSeasonId) {
            postDeferredPayment({
                toggle: isChecked ? 'off' : 'on',
                farm_season_product_plan_id: farmSeasonProductPlanId,
                farm_season_id: farmSeasonId,
            });
        }
    };
    useEffect(() => {
        setIsLoading(false);
        if (isSuccess) {
            setIsChecked(!isChecked);
        }
        else if (isError) {
            enqueueSnackbar(t('errors.deferred-payment'), { variant: 'error' });
        }
    }, [isSuccess, isError]);
    useEffect(() => {
        var _a, _b, _c, _d;
        setIsChecked(!!((_b = (_a = baselineFarmSeason === null || baselineFarmSeason === void 0 ? void 0 : baselineFarmSeason.farm_season_product_plan) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.deferred_payment_start_date) &&
            ((_d = (_c = baselineFarmSeason === null || baselineFarmSeason === void 0 ? void 0 : baselineFarmSeason.farm_season_product_plan) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.status) === 'active');
    }, [(_w = (_v = baselineFarmSeason === null || baselineFarmSeason === void 0 ? void 0 : baselineFarmSeason.farm_season_product_plan) === null || _v === void 0 ? void 0 : _v[0]) === null || _w === void 0 ? void 0 : _w.deferred_payment_start_date]);
    return (isDeferredPaymentActivable && computedCanApplyDeferredPayment) || isDeferredPaymentActive ? (_jsxs("div", Object.assign({ className: classes.container }, { children: [_jsxs("div", Object.assign({ className: classes.wrapper }, { children: [_jsx(Box, Object.assign({ className: classes.box }, { children: _jsx(InfoOutlined, { className: classes.icon }) })), _jsxs(Typography, Object.assign({ sx: { px: '16px' } }, { children: [isDeferredPaymentActive
                                ? t('farmers.deferred-payment.description.active.first')
                                : isChecked
                                    ? t('farmers.deferred-payment.description.on.first')
                                    : t('farmers.deferred-payment.description.off.first'), _jsx("span", Object.assign({ style: { fontWeight: 'bold' } }, { children: isDeferredPaymentActive
                                    ? t('farmers.deferred-payment.description.active.second')
                                    : isChecked
                                        ? t('farmers.deferred-payment.description.on.second')
                                        : t('farmers.deferred-payment.description.off.second') })), isChecked && !isDeferredPaymentActive
                                ? t('farmers.deferred-payment.description.on.third')
                                : !isDeferredPaymentActive
                                    ? t('farmers.deferred-payment.description.off.third')
                                    : ''] }))] })), !hasBaselineStarted && !isLoading && (_jsx(FormControlLabel, { control: _jsx(Switch, { checked: isChecked, onChange: handleSwitch, className: classes.switch, sx: { m: 1 } }), label: _jsx(Typography, Object.assign({ fontWeight: "bold" }, { children: isChecked ? t('farmers.deferred-payment.active') : t('farmers.deferred-payment.inactive') })), labelPlacement: "start" })), isLoading && _jsx(CircularProgress, { size: 30 })] }))) : null;
};

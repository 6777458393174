import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGetCohorts } from '@hooks/useGetCohorts';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Box, FormControl, MenuItem, SvgIcon, Typography } from '@mui/material';
import { Select } from '@components/Select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCohortFilterStyle } from './CohortFilter.style';
export const cohortDefaultValue = 'all';
export const CohortFilter = ({ defaultValue, onChange, className }) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState(defaultValue !== null && defaultValue !== void 0 ? defaultValue : cohortDefaultValue);
    const { classes } = useCohortFilterStyle();
    const { data: cohorts } = useGetCohorts();
    const getCohortFilterText = (value) => value === cohortDefaultValue ? t('common.all') : t(`farmers.cohort.${value}`);
    const handleChange = (event) => {
        const id = event.target.value;
        const cohort = cohorts === null || cohorts === void 0 ? void 0 : cohorts.find((cohort) => cohort.id === id);
        if (cohort)
            setInputValue(cohort.slug);
        else
            setInputValue(cohortDefaultValue);
        const cohortId = !cohort ? undefined : id;
        const cohortFilter = !cohort || cohort.slug === cohortDefaultValue
            ? getCohortFilterText(cohortDefaultValue)
            : getCohortFilterText(cohort === null || cohort === void 0 ? void 0 : cohort.slug);
        onChange === null || onChange === void 0 ? void 0 : onChange(cohortFilter, cohortId);
    };
    if (!cohorts)
        return _jsx(_Fragment, {});
    return (_jsx(FormControl, Object.assign({ className: className }, { children: _jsxs(Select, Object.assign({ IconComponent: KeyboardArrowUpIcon, className: classes.select, value: inputValue, onChange: handleChange, name: "cohort-select", renderValue: (value) => {
                return (_jsxs(Box, Object.assign({ className: classes.selectWrapper }, { children: [_jsx(SvgIcon, Object.assign({ className: classes.selectIcon }, { children: _jsx(PeopleAltOutlinedIcon, {}) })), _jsxs(Typography, Object.assign({ variant: "body1", className: classes.selectOption }, { children: [t('common.cohort'), " : ", getCohortFilterText(value)] }))] })));
            } }, { children: [_jsx(MenuItem, Object.assign({ value: cohortDefaultValue }, { children: t('common.all') })), cohorts === null || cohorts === void 0 ? void 0 : cohorts.map((cohort) => (_jsx(MenuItem, Object.assign({ className: classes.menuItem, value: cohort.id }, { children: t(`farmers.cohort.${cohort.slug}`) }), cohort.id)))] })) })));
};

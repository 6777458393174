var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import useCollapsableStyles from '@features/farmer/profile/components/FarmerDashboard/Collapsable.style';
import { DashboardStep } from '@features/farmer/profile/components/FarmerDashboard/Step/DashboardStep';
import { useResultStatus } from '@features/farmer/profile/farmSeasonStatus/ResultStatus/useResultStatus';
import { PublishResultsModal } from '@features/farmer/profile/resultsPublication/PublishResultsModal';
import { UnpublishResultsModal } from '@features/farmer/profile/resultsPublication/UnpublishResultsModal';
import { useToggle } from '@hooks/useToggle';
import CheckCircle from '@mui/icons-material/CheckCircle';
import LeaderboardRoundedIcon from '@mui/icons-material/LeaderboardRounded';
import { Box, Button } from '@mui/material';
import { useLazyGetRawDndcLogsQuery, useLazyGetRawDndcResultsQuery } from '@services/results.api';
import { CARBON_RESULT_MODEL } from '@utils/constants';
import { useTranslation } from 'react-i18next';
export const RESULT_DATA_TEST_ID = {
    PUBLISHED: 'published',
    NOT_PUBLISHED: 'not-published',
};
export const ResultStatus = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { t } = useTranslation();
    const { classes } = useCollapsableStyles();
    const { canPublishOrUnpublish, resultsStatus } = useResultStatus(props);
    const { open: openPublishModal, handleOpen: onPublishModalOpen, handleClose: onPublishModalClose } = useToggle();
    const { open: openUnpublishModal, handleOpen: onUnpublishModalOpen, handleClose: onUnpublishModalClose, } = useToggle();
    const [triggerGetRawDndcResults] = useLazyGetRawDndcResultsQuery();
    const [triggerGetRawDndcLogs] = useLazyGetRawDndcLogsQuery();
    const farmerName = ((_a = props === null || props === void 0 ? void 0 : props.profile) === null || _a === void 0 ? void 0 : _a.first_name) && ((_b = props === null || props === void 0 ? void 0 : props.profile) === null || _b === void 0 ? void 0 : _b.last_name)
        ? `${(_c = props === null || props === void 0 ? void 0 : props.profile) === null || _c === void 0 ? void 0 : _c.first_name} ${(_d = props === null || props === void 0 ? void 0 : props.profile) === null || _d === void 0 ? void 0 : _d.last_name}`
        : undefined;
    const farmName = (_g = (_f = (_e = props === null || props === void 0 ? void 0 : props.profile) === null || _e === void 0 ? void 0 : _e.farm_user) === null || _f === void 0 ? void 0 : _f.farm) === null || _g === void 0 ? void 0 : _g.name;
    const harvestYear = (_j = (_h = props === null || props === void 0 ? void 0 : props.farmSeason) === null || _h === void 0 ? void 0 : _h.season) === null || _j === void 0 ? void 0 : _j.harvest_year;
    const onExportRawDndcResults = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            // Trigger the query to fetch the file
            yield triggerGetRawDndcResults({ farmSeasonId: Number(props.farmSeason.id) });
        }
        catch (error) {
            console.error('Error downloading the file:', error);
        }
    });
    const onExportRawDndcLogs = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            // Trigger the query to fetch the file
            yield triggerGetRawDndcLogs({ farmSeasonId: Number(props.farmSeason.id) });
        }
        catch (error) {
            console.error('Error downloading the file:', error);
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(DashboardStep, { icon: resultsStatus.isPublished ? (_jsx(CheckCircle, { "data-testid": RESULT_DATA_TEST_ID.PUBLISHED, className: classes.checkIcon })) : (_jsx(LeaderboardRoundedIcon, { "data-testid": RESULT_DATA_TEST_ID.NOT_PUBLISHED, className: classes.icon })), stepStatus: resultsStatus, by: resultsStatus.author }), _jsxs(Box, Object.assign({ className: classes.container }, { children: [canPublishOrUnpublish && resultsStatus.hasResults && (_jsxs(Box, Object.assign({ className: classes.buttonWrapper }, { children: [_jsx(Button, Object.assign({ size: "small", color: resultsStatus.isPublished ? 'error' : 'primary', onClick: resultsStatus.isPublished ? onUnpublishModalOpen : onPublishModalOpen }, { children: t(resultsStatus.isPublished ? 'farmers.results.unpublish' : 'farmers.results.publish') })), props.carbonModel === CARBON_RESULT_MODEL.DNDC && (_jsx(Button, Object.assign({ size: "small", onClick: onExportRawDndcResults }, { children: t('farmers.results.csv-export') })))] }))), canPublishOrUnpublish && (_jsx(Box, Object.assign({ className: classes.buttonWrapper }, { children: _jsx(Button, Object.assign({ size: "small", onClick: onExportRawDndcLogs }, { children: t('farmers.results.logs-export') })) })))] })), !!farmerName && !!farmName && !!harvestYear && (_jsxs(_Fragment, { children: [_jsx(PublishResultsModal, { open: openPublishModal, onClose: onPublishModalClose, farmSeasonId: Number(props.farmSeason.id), farmerName: farmerName, farmName: farmName, harvestYear: harvestYear }), _jsx(UnpublishResultsModal, { open: openUnpublishModal, onClose: onUnpublishModalClose, farmSeasonId: Number(props.farmSeason.id), farmerName: farmerName, farmName: farmName, harvestYear: harvestYear })] }))] }));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TabPanel } from '@components/TabPanel/TabPanel';
import { AlarmingDataGroupedByType } from '@features/alarmingData/components/AlarmingDataGroupedByType/AlarmingDataGroupedByType';
import { FarmSeasonTitle } from '@features/farmSeason/FarmSeasonTitle/FarmSeasonTitle';
import { Tab, Tabs, Typography } from '@mui/material';
import { LoadingPage } from '@pages';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlarmingDataGroupedByResolutionStyles } from './AlarmingDataGroupedByResolution.style';
const a11yProps = (index) => {
    return {
        id: `alarming-data-tab-${index}`,
        'aria-controls': `alarming-data-tabpanel-${index}`,
    };
};
export const AlarmingDataGroupedByResolution = ({ className, farmSeason, isAlarmingDataFetching, alarmingData, }) => {
    const { t } = useTranslation();
    const { classes } = useAlarmingDataGroupedByResolutionStyles();
    const { carbonProgrammeYear, harvestYear } = farmSeason;
    const [selectedTab, setSelectedTab] = useState(0);
    const unresolvedAlarmingDataCount = alarmingData.unresolved.count;
    const resolvedAlarmingDataCount = alarmingData.resolved.count;
    const handleChange = (event, newValue) => setSelectedTab(newValue);
    return (_jsxs("main", Object.assign({ className: clsx(classes.container, className) }, { children: [isAlarmingDataFetching && _jsx(LoadingPage, {}), _jsx(Typography, Object.assign({ color: "secondary", variant: "h3" }, { children: t('titles.data-analysis') })), _jsx(Typography, Object.assign({ className: classes.description, variant: "body1" }, { children: t('alarming-data.verify') })), _jsx(FarmSeasonTitle, { className: classes.yearTitle, carbonProgrammeYear: carbonProgrammeYear, harvestYear: harvestYear, TypographyProps: { variant: 'h2' } }), _jsxs(Tabs, Object.assign({ className: classes.tabs, classes: { flexContainer: classes.tabsFlexContainer }, TabIndicatorProps: { style: { display: 'none' } }, value: selectedTab, onChange: handleChange, "aria-label": "alarming data tabs" }, { children: [_jsx(Tab, Object.assign({ className: clsx(classes.tab, selectedTab === 0 && classes.selectedTab), label: t('alarming-data.tabs.todo', { count: unresolvedAlarmingDataCount }) }, a11yProps(1))), _jsx(Tab, Object.assign({ className: clsx(classes.tab, selectedTab === 1 && classes.selectedTab), label: t('alarming-data.tabs.completed', { count: resolvedAlarmingDataCount }) }, a11yProps(1)))] })), _jsx(TabPanel, Object.assign({ "aria-labelledby": a11yProps(0).id, id: a11yProps(0)['aria-controls'], value: selectedTab, index: 0 }, { children: _jsx(AlarmingDataGroupedByType, { className: classes.list, data: alarmingData.unresolved }) })), _jsx(TabPanel, Object.assign({ "aria-labelledby": a11yProps(1).id, id: a11yProps(1)['aria-controls'], value: selectedTab, index: 1 }, { children: _jsx(AlarmingDataGroupedByType, { className: classes.list, data: alarmingData.resolved }) }))] })));
};
